import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);


root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();





// // Initial render.
// ReactDOM.render(<App tab="home" />, container);

// // During an update, React would access
// // the root of the DOM element.
// ReactDOM.render(<App tab="profile" />, container);







// // Initial render: Render an element to the root.
// root.render(<App tab="home" />);

// // During an update, there's no need to pass the container again.
// root.render(<App tab="profile" />);
