import React, { Component } from 'react';
import './App.css';
import { Input } from 'semantic-ui-react';
import Card from './Card';
import data from './rmapi.json';

// const api = data;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: [],
            nameResults: [],
            isSponsorResults: []
        };
    }

    searchByName = (e) => {
        let lowercase = e.target.value.toLowerCase();
        let newCards = [];
        if (lowercase !== "") {
            newCards = this.state.cards.filter((currentCard) => {
                return  currentCard.lastName.toLowerCase().startsWith(lowercase) || currentCard.firstName.toLowerCase().startsWith(lowercase) || currentCard.state.toLowerCase().startsWith(lowercase);
            });
        }
        this.setState({ nameResults: newCards });
    };

    async componentDidMount() {
        // const messageFetch = await fetch(data);
        this.setState({ cards: data.results });
    }

    render() {
        return (
            <div className='App'>
                <div className='centered-container'>
                <img
                    className='imageHead'
                    alt='TikTok'
                    src='https://www.edigitalagency.com.au/wp-content/uploads/TikTok-icon-glyph.png'
                />
                <h1 className='head wrap-background'>WhoBannedTikTok.com</h1>
                <h2 className='minor wrap-background'>All US Congress</h2>
                <h3 className='mid wrap-background'>
                    Anti-TikTok members are in
                    <div className='why'>RED</div>
                    Pro-TikTok members are in
                    <div className='whyNot'>GREEN</div>
                    Unknown members are in
                    <div className='whyNotUnknown'>WHITE</div>
                    Click on card to email.</h3>
                <Input
                    onChange={this.searchByName}
                    className='search'
                    size='small'
                    icon='search'
                    placeholder='Search by State or Name'
                />
                <br />
                <div className="search-results">
                    {this.state.nameResults.length > 0 &&
                        <Card cards={this.state.nameResults} />
                    }
                </div>
                <div className='footer wrap-background'>
                    <p>All information sourced from congress.gov.
                <br />
                    Site will be updated as more information becomes available.</p>
                </div>
                </div>
            </div>
        );
    }

}

export default App;
