import React, { useState } from 'react';
// import './CardComponent.css';

const Card = ({ cards }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCardClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {cards.map((card) => (
        <figure
          key={card.id}
          className={`snip1579 ${isExpanded ? 'expanded' : ''} ${card.isSponsor ? 'card-sponsor' : ''}`}
          onClick={handleCardClick}
        >
          <img className="boxes" src={card.image} alt="profile-sample2" />
          <figcaption>
            <a href={card.emailLink != null ? card.emailLink : ''}>
              <h2 style={{ color: card.antiTikTok === true  ? 'red' : card.antiTikTok === false ? 'rgb(60, 255, 0)' : 'white' }}>{card.firstName} {card.lastName}</h2>
              <h3 style={{ color: card.isSponsor ? 'red' : 'inherit' }}>{card.isSponsor === true ? 'Co-Sponsor' : ' '}</h3>
              <h3 style={{ color: card.antiTikTok ? 'red' : !card.antiTikTok ? 'rgb(60, 255, 0)' : 'inherit' }}>{card.antiTikTok === true ? 'Anti-TikTok' : card.antiTikTok === false ? 'Pro TikTok' : ' '}</h3>
              {/* <h3 style={{ color: 'white'}}>Bought META STOCK<br></br>{card.boughtMeta2023 != null ? card.boughtMeta2023 : ' '}</h3> */}
              <div className="split">
                <div className="left">
                  <div className="status">{card.party}</div>
                </div>
                <div className="right">{card.state}</div>
              </div>
              <hr />
              <p className='centerP'>
                Phone:
                <br />
                <div>{card.phoneNumber != null ? card.phoneNumber : 'Unknown'}</div>
              </p>
              <p>
                Address
                <br />
                <div>{card.officeAddress != null ? card.officeAddress : 'Unknown'}</div>
              </p>
              <br />
              <br />
            </a>
          </figcaption>
        </figure>
      ))}
    </>
  );
};

export default Card;
